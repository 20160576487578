html,
body {
  height: 100%;
  margin: 0;
}

#app {
  height: 100%;
}

a:hover {
  text-decoration: underline;
}
