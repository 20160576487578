circle#dot1 {
  animation: jump 1.3s linear infinite;
}

circle#dot2 {
  animation: jump 1.3s linear infinite;
  animation-delay: -1.1s;
}

circle#dot3 {
  animation: jump 1.3s linear infinite;
  animation-delay: -0.9s;
}

@keyframes jump {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}
